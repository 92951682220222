/* Vendor files ------------------------------------ */

// import hamburgerMenu from './partial/aos';
import anchorsections from './partial/anchor-highlight-section';
import hideOnScroll from './partial/hide-on-scroll.js';
import changeOnScroll from './partial/header-change-on-scroll.js';
import hamburgerMenu from './partial/hamburger-menu';

// Move header top links on mobile
$(document).ready(function () {
	function movePaste() {
		var currentWidth = window.innerWidth;
		if (currentWidth < 900) {
			$('.js-main-nav .header__menu').append($('.main__lang'));
		} else {
			$('.header__wrap').append($('.main__lang'));
		}
	}
	movePaste();
	$(window).resize(movePaste);

	function varCreate() {
		var headerMobile = document.querySelector('.header__wrap');
		var headerHeight = headerMobile.clientHeight;

		var header = document.querySelector('.mainHeader');
		var height = header.clientHeight;

		document.documentElement.setAttribute(
			'style',
			`--header-height-mobile: ${headerHeight}px;
		--header-height: ${height}px`
		);
	}
	varCreate();
	$(window).resize(varCreate);
});

// Counter
const stats = document.querySelectorAll('.counter');

stats.forEach((stat) => {
	// pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
	const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?(\D+)?(\d+)(\D+)?(\D+)?(\d+)?(\D+)?/;
	const time = 1000;
	let result = [...patt.exec(stat.textContent)];
	let fresh = true;
	let ticks;

	// Remove first full match from result array (we dont need the full match, just the individual match groups).
	result.shift();
	// Remove undefined values from result array where they didnt have a match in one of the optional regex groups
	result = result.filter((res) => res != null);

	while (stat.firstChild) {
		stat.removeChild(stat.firstChild);
	}

	for (let res of result) {
		if (isNaN(res)) {
			stat.insertAdjacentHTML('beforeend', `<span>${res}</span>`);
		} else {
			for (let i = 0; i < res.length; i++) {
				stat.insertAdjacentHTML(
					'beforeend',
					`<span data-value="${res[i]}">
						<span>&ndash;</span>
						${Array(parseInt(res[i]) + 1)
							.join(0)
							.split(0)
							.map(
								(x, j) => `
							<span>${j}</span>
						`
							)
							.join('')}
					</span>`
				);
			}
		}
	}

	ticks = [...stat.querySelectorAll('span[data-value]')];

	let activate = () => {
		let top = stat.getBoundingClientRect().top;
		let offset = (window.innerHeight * 3) / 4;

		setTimeout(() => {
			fresh = false;
		}, time);

		if (top < offset) {
			setTimeout(
				() => {
					for (let tick of ticks) {
						let dist = parseInt(tick.getAttribute('data-value')) + 1;
						tick.style.transform = `translateY(-${dist * 100}%)`;
					}
				},
				fresh ? time : 0
			);
			window.removeEventListener('scroll', activate);
		}
	};
	window.addEventListener('scroll', activate);
	activate();
});

jQuery.fn.scrollCenter = function (elem, speed) {
	var active = jQuery(this).find(elem); // find the active element
	var activeWidth = active.width() / 2; // get active width center
	var pos = active.position().left + activeWidth; //get left position of active li + center position
	var elpos = jQuery(this).scrollLeft(); // get current scroll position
	var elW = jQuery(this).width(); //get div width
	//var divwidth = jQuery(elem).width(); //get div width
	pos = pos + elpos - elW / 2 + 16 + 'px'; // for center position if you want adjust then change this

	jQuery(this).animate(
		{
			scrollLeft: pos,
		},
		speed == undefined ? 1000 : speed
	);
	return this;
};

$('#sticky-js a.selected').trigger('click');
