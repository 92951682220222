/* ----------------------------------------------------
// Header change on scroll
// ------------------------------------------------- */

$(document).ready(function () {
	$(function () {
		$(document).scroll(function () {
			var $nav = $('header');
			var $body = $('body');
			$nav.toggleClass('scroll-change', $(this).scrollTop() > $nav.height());
			$body.toggleClass('scroll-change', $(this).scrollTop() > $nav.height() + 600);
		});
	});
});
